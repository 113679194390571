div.dataTables_wrapper {
    padding-top: 10px;
    position: relative;
}

div.dataTables_length label {
    font-weight: normal;
    float: left;
    text-align: left;
}

div.dataTables_length select {
    width: 75px;
}

div.dataTables_filter label {
    font-weight: normal;
    float: right;
}

div.dataTables_filter input {
    width: 16em;
}

div.dataTables_info {
    padding-top: 8px;
}

div.dataTables_paginate {
    float: right;
    margin: 0;
}

div.dataTables_paginate ul.pagination {
    margin: 2px 0;
    white-space: nowrap;
}

table.dataTable,
table.dataTable td,
table.dataTable th {
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
}

table.dataTable>thead>tr>th
{
    border-top: 1px solid #ddd !important;
    padding-right: 20px;
}

table.dataTable>thead>tr>th,
table.dataTable td,
table.dataTable th {
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
}


table.dataTable {
    clear: both;
    margin-top: 6px !important;
    margin-bottom: 6px !important;
    max-width: none !important;
    width: 100% !important;
}

table.dataTable thead .sorting,
table.dataTable thead .sorting_asc,
table.dataTable thead .sorting_desc,
table.dataTable thead .sorting_asc_disabled,
table.dataTable thead .sorting_desc_disabled {
    cursor: pointer;
}

table.dataTable th:active {
    outline: none;
}

/* Scrolling */
div.dataTables_scrollHead table {
    margin-bottom: 0 !important;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

div.dataTables_scrollHead table thead tr:last-child th:first-child,
div.dataTables_scrollHead table thead tr:last-child td:first-child {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}

div.dataTables_scrollBody table {
    border-top: none;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}

div.dataTables_scrollBody tbody tr:first-child th,
div.dataTables_scrollBody tbody tr:first-child td {
    border-top: none;
}

div.dataTables_scrollFoot table {
    margin-top: 0 !important;
    border-top: none;
}




/*
 * TableTools styles
 */
.table tbody tr.active td,
.table tbody tr.active th {
    background-color: #08C;
    color: white;
}

.table tbody tr.active:hover td,
.table tbody tr.active:hover th {
    background-color: #0075b0 !important;
}

.table tbody tr.active a {
    color: white;
}

.table-striped tbody tr.active:nth-child(odd) td,
.table-striped tbody tr.active:nth-child(odd) th {
    background-color: #017ebc;
}

table.DTTT_selectable tbody tr {
    cursor: pointer;
}

div.DTTT .btn {
    color: #333 !important;
    font-size: 12px;
}

div.DTTT .btn:hover {
    text-decoration: none !important;
}

ul.DTTT_dropdown.dropdown-menu {
    z-index: 2003;
}

ul.DTTT_dropdown.dropdown-menu a {
    color: #333 !important; /* needed only when demo_page.css is included */
}

ul.DTTT_dropdown.dropdown-menu li {
    position: relative;
}

ul.DTTT_dropdown.dropdown-menu li:hover a {
    background-color: #0088cc;
    color: white !important;
}

div.DTTT_collection_background {
    z-index: 2002;
}

/* TableTools information display */
div.DTTT_print_info.modal {
    height: 150px;
    margin-top: -75px;
    text-align: center;
}

div.DTTT_print_info h6 {
    font-weight: normal;
    font-size: 28px;
    line-height: 28px;
    margin: 1em;
}

div.DTTT_print_info p {
    font-size: 14px;
    line-height: 20px;
}

/*
 * FixedColumns styles
 */
div.DTFC_LeftHeadWrapper table,
div.DTFC_LeftFootWrapper table,
div.DTFC_RightHeadWrapper table,
div.DTFC_RightFootWrapper table,
table.DTFC_Cloned tr.even {
    background-color: white;
}

div.DTFC_RightHeadWrapper table ,
div.DTFC_LeftHeadWrapper table {
    margin-bottom: 0 !important;
    border-top-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}

div.DTFC_RightHeadWrapper table thead tr:last-child th:first-child,
div.DTFC_RightHeadWrapper table thead tr:last-child td:first-child,
div.DTFC_LeftHeadWrapper table thead tr:last-child th:first-child,
div.DTFC_LeftHeadWrapper table thead tr:last-child td:first-child {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}

div.DTFC_RightBodyWrapper table,
div.DTFC_LeftBodyWrapper table {
    border-top: none;
    margin-bottom: 0 !important;
}

div.DTFC_RightBodyWrapper tbody tr:first-child th,
div.DTFC_RightBodyWrapper tbody tr:first-child td,
div.DTFC_LeftBodyWrapper tbody tr:first-child th,
div.DTFC_LeftBodyWrapper tbody tr:first-child td {
    border-top: none;
}

div.DTFC_RightFootWrapper table,
div.DTFC_LeftFootWrapper table {
    border-top: none;
}

.dataTables_processing {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 250px;
    height: 80px;
    margin-left: -125px;
    margin-top: -15px;
    padding: 15px 0 30px 0;
    border: 1px solid #ddd;
    text-align: center;
    color: #444;
    font-size: 14px;
    background: url("../images/loading_bar.gif") no-repeat center 38px #fff;
    box-shadow: 2px 2px 5px #444;
    -moz-box-shadow: 2px 2px 5px #444;
    -webkit-box-shadow: 2px 2px 5px #444;
    z-index: 9999;
}

.pagination-sm > li > a span {
    padding-bottom: 5px;
}

td.details-control {
    background: url('../images/details_open.png') no-repeat center center;
    cursor: pointer;
    width: 18px;
}
tr.shown td.details-control {
    background: url('../images/details_close.png') no-repeat center center;
}
tr.selected{
    background-color: #acbad4;
}