.panel-heading-0{
    padding-left: 0px;
}
.midium-font{
	font-size: 15px;
	letter-spacing:-0.015em;
}
.navigation > li > a {
	font-weight: bolder;
}
.text-bold{
	font-weight: bolder;
}
.has-stik{
  color: red;
  font-weight: bold;
}
.error{
  color: red;
}
.row > .col-sm-12 > .alert{
	margin-bottom: 0px;
	margin-top: 1%;
	padding-left: 15px;
}
.form-control:focus,
input[type=checkbox]:focus,
input[type=radio]:focus {
   background: #FFFFFF;
   outline: 1px solid #3c8dbc;
}

.panel-body .btn:focus,
.box .btn:focus,
.box button:focus,
.box a:focus,
.box button:active {
   outline: 1px solid #3c8dbc;
}

.table > thead > tr > th, .table > tbody > tr > th, .table > tfoot > tr > th, .table > thead > tr > td, .table > tbody > tr > td, .table > tfoot > tr > td{
	border-top:0px solid #ddd;
}

.custom-div{
	width:12.5%;
	float:left;
	padding:12px 14px;
}
.caption1{
	height: 50px;
	text-align: center;
}

.margin-top-5px{
	margin-top: 5px;
}

.dataTables_wrapper .dataTables_filter input{
	margin-left: 0.5em;
}

.dataTables_length{
	padding-right: 0.7em;
}

.dataTables_info{
	padding-left: 0.7em;
}
.dataTables_paginate .paging_simple_numbers{
	padding-right: 1.00em;
}
th{
	font-weight: bold;
}

.page-title .set-height{
	height: 34px;
}

.thumbnail{
	height: 225px;
}

.thumbnail > .thumb > img {
	height: 161.70px;
}

/* form master modal CSS */
.form_master_modal > .modal-dialog > .modal-content{
	height: 450px;
}

.table-bordered > thead > tr > td, .table-bordered > thead > tr > th{
    border-bottom-width: 1px;
    padding: 5px;
    font-size: 12px;
    border: 1px solid #ddd;
}
.table-bordered > tbody > tr > td, .table-bordered > tbody > tr > td{
    padding: 5px;
    font-size: 13px;
    border: 1px solid #ddd;
}
.table > thead > tr > th, .table > tbody > tr > th, .table > tfoot > tr > th, .table > thead > tr > td, .table > tbody > tr > td, .table > tfoot > tr > td{
	vertical-align: top;
}
.valign{
	vertical-align: middle !important;
}
.label-width{
	width: 200px;
}
.validation-width{
	width: 500px;
}

form .select2-container--default .select2-selection--multiple, form .select2-selection .select2-selection--multiple {
    border: 1px solid #d2d6de;
    border-radius: 0;
}

.select2-container--focus {
    border: solid #3c8dbc 1px;
}
/* Used for width of admore td*/
.firts_column{
	width:10%;
}
.second_column{
	width:20%;
}
.third_column{
	width:30%;
}
.fourth_column{
	width:40%;
}

.table > tbody > tr > th, .table > tfoot > tr > th, .table > thead > tr > td, .table > tbody > tr > td, .table > tfoot > tr > td{
	border-top:1px solid #ddd;
}
.table-bordered tr:first-child > td, .table-bordered tr:first-child > th {
    border-top: none;
}
.margin_bottom{
	margin-bottom: 1.5em;
}
.margin_top{
	margin-top: 5em;
}
.padding{
	padding-left: 0.8em;
}

.mobile{
	font-size: 1.5em;
    margin-left: 8px;
}
.display a {
	display: block;
	color: #000;

}
.icon-mobile{
	color: #98c34a;
	margin-right: 0.4em;
}
.icon-satellite{
	color: #9c27b0;
	margin-right: 0.4em;
}
.icon-power{
	color: #FFC0CB;
	margin-right: 0.4em;
}
.iconfire{
	color: #f44b36;
	margin-right: 0.4em;
}
.font-icontext{
	font-size: 18px;
}

.margin-top-30{
	margin-top: 30px;
}
.form-control[disabled], .form-control[readonly], fieldset[disabled] .form-control{
	background-color : #eee;
	opacity :1;
}
.inr-size{
	font-size: 30px !important;
}
.list-size{
	font-size: 16px;
	font-weight: bold;
}

/* this css used for locker print */

div.lockerDiv{
	width:40%;
	border-bottom:2px dotted black;
	float:left;
}
div.lockerDiv50{
	width:50%;
	border-bottom:2px dotted black;
	float:left;
}
div.lockerDivNoBorder{
	width:50%;
	float:left;
}
div.print{
	margin-bottom:100px;
}
div.topMargin{
	margin-top:30px;
}
p.lockerP{
	font-size:15px;
	width:90%;
	border-bottom:2px dotted black;
}
.font_15{
	font-size:15px;
}
div.lockerDiv30{
	width:30%;
	border-bottom:2px dotted black;
	float:left;
}
div.lockerDiv70{
	width:60%;
	border-bottom:2px dotted black;
	float:left;
}
div.lockerDiv30Mo{
	width:30%;
	border-bottom:2px dotted black;
	float:left;
	word-wrap: break-word;

}
div.lockerDiv70PSK{
	width:60%;
	border-bottom:2px dotted black;
	float:left;
	word-wrap: break-word;
}
/* User Agreement Print CSS Start */
@media print {
    div.page-header {
        display: none;
    }
    /*div.content-wrapper{
    	padding: 0px;
    	margin: 0px;
    }*/

    div.panel{
    	border:none;
    }

	p.lockerP{
		font-size:15px;
		width:100%;
		border-bottom:2px dotted black;
	}
    div.lockerDiv{
    	width:40%;
		border-bottom:2px dotted black;
		float:left;
    	word-wrap: break-word;
    }
    div.lockerDiv50{
    	word-wrap: break-word;
    	width:60%;
		border-bottom:2px dotted black;
		float:left;
    }
    div.lockerDiv30{
		width:50%;
		border-bottom:2px dotted black;
		float:left;
    	word-wrap: break-word;

	}
	div.lockerDiv30Mo{
		width:35%;
		border-bottom:2px dotted black;
		float:left;
    	word-wrap: break-word;

	}
	div.lockerDiv70PSK{
		width:65%;
		border-bottom:2px dotted black;
		float:left;
    	word-wrap: break-word;
	}
	div.font_15{
		overflow:hidden;
	}
	div.alert{
		display: none;
	}
	div.sidebar{
		display: none;
	}
}
/* User Agreement Print CSS End */

/* start revenue report css*/
div.serchfield{
	margin-top: 15px;
	margin-bottom: 15px;
}
.table_bordered {
  border: 1px solid #ddd;
}
.table_bordered > thead > tr > th,
.table_bordered > tbody > tr > th,
.table_bordered > tfoot > tr > th,
.table_bordered > thead > tr > td,
.table_bordered > tbody > tr > td,
.table_bordered > tfoot > tr > td {
  border: 1px solid #ddd;
}
.table > thead > tr > th.last_login,
.table > tbody > tr > td.last_login{
	padding: 10px;
}

.table_bordered > thead > tr > th,
.table_bordered > thead > tr > td {
  border-bottom-width: 2px;
}
/* end revenue report css*/

.table_bordered > tbody > tr > td.date_field {
	padding-right: 5px;
	padding-left: 10px;
	width: 10%;
}
.datatable-form-filter > .lang-select{
	width: 160px;
}

.margin {
	margin-top: 1em;
}
.myerror{
	color:red;
}

.app > .app-content > .box >.overlay {
    margin: 0px;
	padding: 0px;
	position: fixed;
	right: 0px;
	top: 0px;
	width: 100%;
	height: 100%;
	background: rgba(255,255,255,0.75);
	opacity: 0.8;
	z-index: 200;
}

.app > .app-content > .box > .ajax-overlay {
    z-index: 999;
	background: rgba(255,255,255,.7);
	border-radius: 3px;
}

.app > .app-content > .box > .ajax-overlay > i {
    position: relative;
    top: 50%;
    left: 50%;
    z-index: 99999;
    opacity: 1;
    transform: translateX(-50%);
}
.no-left-padding{
	padding-left: 0px;
}
.check_link{
	padding-left: 10px;
}
.check_button{
	padding:3px 7px;
}
div.submitField{
	margin-top: 15px;
	margin-bottom: 15px;
}
.pending_heading{
	float:left;
}

.status_lbl{
	width: 70px;
}
/* Used in Transaction Report start*/
.table > thead > tr > th.tr_heading,
.table > tbody > tr > td.tr_heading{
	padding: 6px;
}
/* Used in Transaction Report end*/
/* Used in Recharge Report start*/
.table > thead > tr > th.rg_coulmn,
.table > tbody > tr > td.rg_coulmn{
	padding: 6px;
}
/* Used in Recharge Report end*/

/* Below css used in locker for display title proper start */
.fancybox-title-float-wrap{
	left: 0%;
	word-wrap: break-word;
}
/* Below css used in locker for display title proper End */

.box.box-warning {
    border-top-color: #f39c12;
}
.filter-out {
    display: none;
}
.box, .small-box {
    box-shadow: 0 1px 1px rgba(0,0,0,.1);
}
.box-header.with-border {
    border-bottom: 1px solid #f4f4f4;
}
.box-header {
    color: #444;
    display: block;
    padding: 10px;
    position: relative;
}
.box-header .box-title, .box-header>.fa, .box-header>.glyphicon, .box-header>.ion {
    display: inline-block;
    font-size: 18px;
    margin: 0;
    line-height: 1;
}
.fa {
    display: inline-block;
    font: normal normal normal 15px/1 FontAwesome !important;
    font-size: 15px !important;
}
.has-error .select2-selection--single:not([class*=bg-]):not([class*=border-]) {
    border-color: #D84315 !important;
}
.table > thead > tr > th,
.table > tbody > tr > th,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > tbody > tr > td,
.table > tfoot > tr > td {
    /*padding: 12px 10px;*/
    padding: 5px 8px;
}
.bg-yellow, .callout.callout-warning,.modal-warning .modal-body {
    background-color: #f39c12!important;
}
.info-box {
    min-height: 90px;
    /* background: #fff; */
    width: 100%;
    box-shadow: 0 1px 1px rgba(0,0,0,.1);
    border-radius: 2px;
    margin-bottom: 15px;
}
.info-box, .info-box-icon, .info-box-more, .info-box-number {
    display: block;
}
.info-box-icon .fa {
    display: inline-block;
    width: 100%;
    height: 100%;
    line-height: 85px;
}
.info-box-icon {
    float: left;
    height: 90px;
    width: 90px;
    text-align: center;
    font-size: 45px;
    line-height: 90px;
    background: rgba(0,0,0,.2);
    border-radius: 2px 0 0 2px;
}
.info-box-content {
    padding: 5px 10px;
    margin-left: 90px;
}
.info-box-text, .progress-description {
    display: block;
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.info-box-number {
    font-weight: 700;
    font-size: 18px;
}

.info-box, .info-box-icon, .info-box-more, .info-box-number {
    display: block;
}
.info-box .progress, .info-box .progress .progress-bar {
    border-radius: 0;
}
.info-box .progress {
    background: rgba(0,0,0,.2);
    margin: 5px -10px;
    height: 2px;
}
.info-box .progress .progress-bar {
    background: #fff;
}
.info-box .progress, .info-box .progress .progress-bar {
    border-radius: 0;
}
.info-box-text, .progress-description {
    display: block;
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.info-box-icon .fa {
    display: inline-block;
    width: 100%;
    height: 100%;
    line-height: 85px;
    font-size: inherit !important;
}
.white, .white:hover, .white:active {
    color: #FFF;
}

.bg-red, .callout.callout-danger,.modal-danger .modal-body {
    background-color: #dd4b39!important;
}
.bg-light-purple {
    background-color: #9794C5;
    color: #F2F2F2;
}
.bg-aqua, .callout.callout-info, .modal-info .modal-body {
    background-color: #00c0ef!important;
}
.bg-light-maroon {
    background-color: #F2598F;
    color: #F2F2F2;
}
.bg-light-purple {
    background-color: #9794C5;
    color: #F2F2F2;
}
.bg-light-primary {
    background-color: #7FB4D2;
    color: #F2F2F2;
}
.bg-dark-wood
{
     background-color:  #994d00;
     color: #F2F2F2;

}
.box.box-info {
    border-top-color: #00c0ef;
    margin-top: 10px;
}
.box-header {
    color: #444;
    display: block;
    padding: 10px;
    position: relative;
}
.box-header.with-border {
    border-bottom: 1px solid #f4f4f4;
}
.box-body .table > thead > tr > th,
.box-body .table > tbody > tr > th,
.box-body .table > tfoot > tr > th,
.box-body .table > thead > tr > td,
.box-body .table > tbody > tr > td,
.box-body .table > tfoot > tr > td
{
    padding: 6px 10px;
}

.pagination
{
	margin-top: 10px;
}

@media (min-width: 769px)
{
    .sidebar-xs .sidebar-main .navigation > li > ul {
        position: relative;
        /*right: -260px;*/
        top: 0px;
        width: 260px;
        display: none;
        left: 53px;
        background-color: #263238;
        border-left: 1px solid rgba(255, 255, 255, 0.1);
        border-radius: 0 0 3px 0;
    }
}

.table-responsive {
    overflow-x: visible;
}
.open > .dropdown-menu {
    display: block;
    z-index: 99999;
}
 thead th img {
    width: 19px;
}
@media print {
    .no-print { display: none; }
}
.showPerPage{
    position: absolute;
    top:15px;
    right:20px;
}
.showPerPage .btn{
    margin-top: -1px;
}
.perPage {
    height: 36px;
    padding: 7px 12px;
    font-size: 13px;
    line-height: 1.5384616;
    color: #333333;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ddd;
    border-radius: 3px;
}
.external-row
{
    background-color: #B6DCFB;
}
.fa.fa-spinner.fa-pulse
{
    font-size: 30px !important;
}
.filter-out {
    display: none;
}
.content-wrapper .panel .panel-heading {
    padding: 10px 10px;
}
.panel-body-searching .navbar-collapse {
    margin-left: 0;
    margin-right: 25px;
}
.page-container {
    padding: 10px 10px;
    padding-bottom: 50px;
}
.capitalize { text-transform: capitalize; }
.uppercase {text-transform: uppercase; }
.year_link {
    min-height: 45px;
    padding-top: 15px !important;
    padding-left: 10px !important;
}
.grey-back {
    background: #E6E6E6;
}
.link-disabled {
    pointer-events: none;
    cursor: default;
    text-decoration: none;
}
.form-control {
    padding: 7px 10px;
}
.negative-row {
    background-color:#eabd69;
}
.remaining_meter-row {
    background-color:#e2e23a;
}
.dt-buttons{
    margin: 0 0 10px 10px;
}
.dataTables_scrollBody {
    min-height: 360px;
}
.location-link {
    min-height: 45px;
    padding-top: 15px !important;
    padding-left: 10px !important;
}
.navbar-inverse {
    color: #FFCC00;
}

.navbar-inverse {
    background-color:#b8860b;
    border-color:#b8860b;
}
.navbar-brand {
    font-weight: 600;
    font-size: 18px;
}
.action_btn_padding {
    padding-right : 30px;
}
.created_by_padding {
    padding-left: 30px;
}
.action_mrg_top{
    margin-top: 40px;
}
/* used for swith toggel */
/* The switch - the box around the slider */
.switch {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 22px;
}

/* Hide default HTML checkbox */
.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

/* The slider */
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 15px;
    width: 15px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked+.slider {
    background-color: rgb(100, 189, 99);
}

input:focus+.slider {
    box-shadow: 0 0 1px rgb(100, 189, 99);
}

input:checked+.slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}
.modal-dialog{
    width: 500px !important;
}
.ui-pnotify-icon span{
    margin-top:6px !important;
}
